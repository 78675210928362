import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import moment from 'moment';

require('select2');
require('daterangepicker');

export default class extends Controller {
  static targets = ['dev', 'repo', 'dates', 'dates2'];

  static values = {
    type: String,
  };

  connect() {
    const self = this;
    const current = moment();

    const currentStartDate = current.clone().startOf('week').subtract(1, 'weeks');
    const currentEndDate = currentStartDate.clone().add(6, 'days').endOf('day');

    const previousStartDate = currentStartDate.clone().subtract(1, 'weeks');
    const previousEndDate = previousStartDate.clone().add(6, 'days').endOf('day');

    const select2Config = {
      width: '100%',
      dropdownAutoWidth: true,
      selectionCssClass: 'select2-no-border',
    };
    const drpConfig = {
      showDropdowns: true,
      alwaysShowCalendars: true,
      autoApply: true,
      maxDate: current,
      autoUpdateInput: false,
      locale: {
        format: 'MMMM DD, YYYY',
      },
      ranges: {
        Today: [currentStartDate, currentEndDate],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 days': [moment().subtract(6, 'days'), current],
        'Last 30 days': [moment().subtract(1, 'month'), current],
      },
    };

    if (this.hasDevTarget) {
      $(this.devTarget).select2(select2Config);
      $(this.devTarget).on('select2:select', () => {
        self.fetchData();
      });
    }

    if (this.hasRepoTarget) {
      $(this.repoTarget).select2(select2Config);
      $(this.repoTarget).on('select2:select', () => {
        self.submitForm();
      });
    }

    if (this.hasDatesTarget) {
      $(this.datesTarget)
        .daterangepicker(
          drpConfig,
          self.drpCallBack(currentStartDate, currentEndDate, this.datesTarget),
        )
        .on('apply.daterangepicker', (_, picker) => this.drpApply(picker));
    }

    if (this.hasDates2Target) {
      $(this.dates2Target)
        .daterangepicker(
          drpConfig,
          self.drpCallBack(
            previousStartDate,
            previousEndDate,
            this.dates2Target,
          ),
        )
        .on('apply.daterangepicker', (_, picker) => this.drpApply(picker));
    }

    if (this.isRemote()) {
      this.submitForm();
    }
  }

  submitForm() {
    if (this.isRemote()) {
      Rails.fire(this.element, 'submit');
    } else {
      this.element.submit();
    }
  }

  isRemote() {
    return this.element.getAttribute('data-remote');
  }

  drpApply(picker) {
    picker.element.val(
      `${picker.startDate.format(
        picker.locale.format,
      )} - ${picker.endDate.format(picker.locale.format)}`,
    );
    this.submitForm();
  }

  drpCallBack(start, end, selector) {
    $(selector).val(
      `${start.format('MMMM DD, YYYY')} - ${end.format('MMMM DD, YYYY')}`,
    );
  }

  fetchData() {
    const target = $(this.devTarget);
    const value = target.val();
    $.ajax({
      url: '/developers/filter',
      type: 'GET',
      data: { dev_value: value },
      success: (data) => {
        if (data.dev) {
          window.location.href = `/developers/${this.typeValue}?dev=${data.dev}`;
        } else {
          console.error('dev value not available');
        }
      },
    });
  }
}
